<script>
import api from '@/utils/axios';

export default {
    data() {
        return {
            email: '',
            password: '',
        };
    },
    methods: {
        async accountSubmit(event) {
            event.preventDefault();
            console.log("Adresse email:", this.email);
            console.log("Mot de passe:", this.password);

            try {
                const response = await api.post('/login', {
                    email: this.email,
                    password: this.password,
                });

                localStorage.setItem('token', response.data.token);
                localStorage.setItem('user_id', response.data.user.id);

                this.$router.push({ name: 'insight' });
            } catch (error) {
                console.error("Erreur lors de la connexion:", error.response.data);
                alert("L'email ou le mot de passe est incorrect.");
            }
        },
    },
};
</script>

<template>
    <div class="container mb-5 mt-3">
        <div class="row">
            <div class="offset-xl-1 col-xl-5 col-md-10 offset-md-1 col-12 border-end border-5 border-white login mt-3">
                <div class="p-5">
                    <h1>déjà inscrit(e) ?</h1>
                    <p>Si vous êtes déjà inscrit(e), veuillez vous connecter ici :</p>
                    <div class="connection_form">
                        <form @submit="accountSubmit" id="connection_form_data">
                            <div>
                                <label for="email">Adresse email *</label>
                                <input v-model="email" id="email" type="email" placeholder required />
                            </div>
                            <div>
                                <label for="password">Mot de passe *</label>
                                <input v-model="password" id="password" type="password" placeholder required />
                            </div>
                            <router-link :to="{ name: 'forgetpassword' }">
                                <p>Vous avez oublié votre mot de passe ?</p>
                            </router-link>
                            <p>Pour plus de renseignements sur la façon dont nous utilisons vos données à caractère
                                personnel,
                                veuillez consulter notre
                                <a href="">Politique de Confidentialité.</a>
                            </p>
                            <div class="text-center">
                                <button type="submit" class="w-75">connexion</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-xl-5 col-md-10 offset-md-1 col-12  register mt-3">
                <div class="p-5">
                    <h1>créez votre compte</h1>
                    <p>Inscrivez-vous pour profiter des avantages de votre compte Du Sablon :</p>
                    <ul>
                        <li>Gérer votre profil</li>
                        <li>Enregistrer vos adresses de livraison</li>
                        <li>Vérifier vos commandes</li>
                        <li>Gérer votre collection et vos services commandés</li>
                    </ul>
                    <div class="text-center">
                        <router-link :to="{ name: 'register' }">
                            <button type="submit" class="w-75">créer mon compte</button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.login,
.register,
input,
button {
    background-color: #ddd;
}

h1,
h2,
p,
input,
li,
label,
.connection_form a {
    font-family: 'Arapey', serif;
}

h1 {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 30px;
}

p {
    font-size: 18px;
}

label {
    margin-top: 20px;
    color: grey;
    font-size: 15px;
}

input {
    border: 0;
    border-bottom: 1px solid #000;
    font-size: 18px;
    margin-bottom: 20px;
    width: 90%;
}

li {
    font-size: 18px;
    margin-top: 10px;
    margin-left: 10px;
}

.connection_form p {
    margin-top: 20px;
    font-size: 15px;
}

.connection_form a {
    color: black;
    text-decoration: underline;
    font-size: 15px;
}

.connection_form a:hover {
    text-decoration: none;
}

button {
    min-height: 50px;
    margin-top: 40px;
    border: 2px solid rgb(227, 167, 14);
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
    color: black !important;
}

button:hover {
    background-color: rgb(227, 167, 14);
    color: white;
}
</style>