<script>
import api from '@/utils/axios';

export default {
    data() {
        return {
            isAuthenticated: false,
            cartItemCount: 0,
        };
    },
    methods: {
        checkAuthentication() {
            const token = localStorage.getItem('token');
            this.isAuthenticated = !!token;
            if (this.isAuthenticated) {
                this.getCartItemCount();
            }
        },
        async getCartItemCount() {
            try {
                const response = await api.get('/cart');
                this.cartItemCount = response.data.cartItems.length;
            } catch (error) {
                console.error('Erreur lors de la récupération du panier:', error);
            }
        },
        updateCartCount() {
            this.getCartItemCount();
        },
        logout() {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            this.isAuthenticated = false;
            this.cartItemCount = 0;
            this.$router.push({ name: 'login' });
        }
    },
    created() {
        this.checkAuthentication();
    },
    watch: {
        '$route'(to, from) {
            this.checkAuthentication();
        }
    }
};
</script>

<template>
    <header>
        <div class="row p-4">
            <div class="col-6 offset-3 d-flex justify-content-center mt-2">
                <router-link :to="{ name: 'home' }">
                    <img class="img-fluid" src="/public/img/DuSablonLogoZoom.png" alt="">
                </router-link>
            </div>
            <div class="col-md-2 offset-md-1 col-1 icons">

                <router-link :to="isAuthenticated ? { name: 'insight' } : { name: 'login' }">
                    <i class="fa-regular fa-circle-user fw-bold"></i>
                </router-link>

                <router-link :to="isAuthenticated ? { name: 'cart' } : { name: 'login' }" class="header-cart-icon">
                    <i class="fa-solid fa-bag-shopping"></i>
                    <span v-if="cartItemCount > 0" class="badge">
                        {{ cartItemCount }}
                    </span>
                </router-link>

            </div>
        </div>
        <div class="container">
            <div class="row mt-5">
                <div class="col-xl-6 offset-xl-3 col-12">
                    <nav class="main-nav-menu">
                        <ul class="d-flex">
                            <li><router-link :to="{ name: 'bracelets' }"
                                    active-class="active-link">BRACELETS</router-link></li>
                            <li><router-link :to="{ name: 'necklaces' }"
                                    active-class="active-link">COLLIERS</router-link></li>
                            <li><router-link :to="{ name: 'rings' }" active-class="active-link">BAGUES</router-link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="col-10 offset-1">
                    <hr>
                </div>
            </div>
        </div>
    </header>
</template>

<style scoped>
i {
    color: #bdbdbd;
    margin-left: 27px;
    margin-top: 20px;
    font-size: 27px;
}

i:hover {
    color: rgb(227, 167, 14);
}

@media (min-width: 576px) {
    /* > col-md */
    .icons {
        display: flex;
        justify-content: center;
    }
}


li {
    margin: auto;
}

.main-nav-menu a {
    color: black;
    font-weight: 600;
    font-size: 20px;
    transition: font-size 0.5s ease-in-out;
}

.main-nav-menu a:hover,
.main-nav-menu .active-link {
    text-decoration: underline;
    text-decoration-color: rgb(227, 167, 14);
    text-decoration-thickness: 0.2rem;
    text-underline-offset: 8px;
}

.header-cart-icon {
    position: relative;
    display: inline-block;
}

.header-cart-icon .badge {
    position: absolute;
    top: 10px;
    right: -10px;
    background-color: grey;
    color: #ffffff;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    border: #ffffff;
}
</style>