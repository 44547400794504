<script>
import UserPanel from "../components/UserPanel.vue"
import api from '@/utils/axios';

export default {
    components: {
        UserPanel,
    },
    data() {
        return {
            order: null,
            orderId: this.$route.params.id,
        };
    },
    mounted() {
        this.fetchOrderDetails();
    },
    methods: {
        async fetchOrderDetails() {
            try {
                const response = await api.get(`/order/${this.orderId}`);
                console.log('Détails de la commande:', response.data.order);
                this.order = response.data.order;
            } catch (error) {
                console.error('Erreur lors de la récupération des détails de la commande:', error);
            }
        },
    },
};
</script>


<template>
    <div class="container">
        <div class="row">
            <UserPanel></UserPanel>
            <div class="col-xl-8 offset-xl-2 col-md-10 offset-md-1 col-12">
                <h1 class="mt-5 fw-bold">Détails de la Commande #{{ orderId }}</h1>

                <div v-if="order">
                    <p class="mt-3">Date : {{ new Date(order.created_at).toLocaleDateString() }}</p>
                    <p>Statut : <span class="text-uppercase">{{ order.status }}</span></p>
                    <p>Numéro de suivi : {{ order.tracking_number }}</p>
                    <p class="fw-bold total">Total : {{ order.total_price }} €</p>

                    <div class="table-responsive my-5">
                        <table class="table table-hover">
                            <thead class="table-dark text-center">
                                <tr>
                                    <th>Nom du produit</th>
                                    <th>Quantité</th>
                                    <th>Prix unitaire</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in order.order_items" :key="item.id" class="text-center">
                                    <td class="fw-bold">{{ item.product_name }}</td>
                                    <td>{{ item.quantity }}</td>
                                    <td>{{ item.product_price }} €</td>
                                    <td class="fw-bold">{{ (item.product_price * item.quantity).toFixed(2) }} €
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
h1 {
    font-family: 'Arapey', serif;
    text-decoration: underline;
    text-decoration-color: rgb(227, 167, 14);
    text-decoration-thickness: 0.2rem;
    text-underline-offset: 8px;
}

p {
    /* font-family: 'Arapey', serif; */
    font-size: 16px;
}

.total {
    font-size: 20px;
}

table {
    font-size: 15px;
}

td,
th {
    padding: 15px;
    text-align: center;
}
</style>
