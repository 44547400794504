<script>
export default {
};
</script>

<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-md-8 offset-md-2 col-12">
                    <hr>
                    <div class="text-center py-3">
                        <h1 class="my-4">NOUS SUIVRE</h1>
                        <nav>
                            <ul class="d-flex justify-content-center">
                                <li class="mx-4 mb-4"><a href=""><i class="fa-brands fa-instagram"></i></a></li>
                                <li class="mx-4"><a href=""><i class="fa-brands fa-facebook"></i></a></li>
                                <li class="mx-4"><a href=""><i class="fa-brands fa-pinterest"></i></a></li>
                            </ul>
                        </nav>
                    </div>
                    <hr>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-xl-5 offset-xl-1 col-md-6 col-12">
                    <div class="text-left">
                        <h1 class="text-center">SERVICE CLIENT</h1>
                        <a href="">
                            <h5 class="mb-2">NOUS CONTACTER </h5>
                        </a>
                        <a href="">
                            <h5>ACHAT EN LIGNE </h5>
                        </a>
                        <a href="">
                            <h5>SUIVEZ VOTRE COMMANDE </h5>
                        </a>
                        <a href="">
                            <h5>Expédition ET LIVRAISON </h5>
                        </a>
                        <a href="">
                            <h5>COMMANDES ET PAIEMENTS </h5>
                        </a>
                        <a href="">
                            <h5>AIDE </h5>
                        </a>
                    </div>
                </div>
                <div class="col-xl-5 col-md-6 col-12">
                    <div class="text-left">
                        <h1 class="text-center">Legal et Confidentialité</h1>
                        <a href="">
                            <h5>CONDITIONS D'UTILISATION</h5>
                        </a>
                        <a href="">
                            <h5>CONDITIONS DE VENTE</h5>
                        </a>
                        <a href="">
                            <h5>POLITIQUE DE RETOUR </h5>
                        </a>
                        <a href="">
                            <h5>POLITIQUE DE CONFIDENTIALITé</h5>
                        </a>
                        <a href="">
                            <h5>POLITIQUE DES COOKIES </h5>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5" id="row_foot">
            <div class="col-6 offset-3 d-flex justify-content-center p-3">
                <img class="img-fluid" src="/public/img/DuSablonLogoZoom2.png" alt="">
            </div>
        </div>
    </footer>
</template>

<style scoped>
footer{
    text-transform: uppercase;
}

h1,
h5 {
    font-family: 'Arapey', serif;
}

h1 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 30px;
}

i {
    color: rgb(227, 167, 14);
    font-weight: 600;
    font-size: 30px;
}

i:hover {
    color: black;
}

h5 {
    font-size: 17px;
    color: #000;
    margin-bottom: 10px;
}

#row_foot {
    background-color: rgb(227, 167, 14);
}
</style>