<script>
import api from '@/utils/axios';

export default {
    data() {
        return {
            form: {
                civility: '',
                firstName: '',
                lastName: '',
                email: '',
                confirmEmail: '',
                phoneNumber: '',
                password: '',
                confirmPassword: '',
                day: '',
                month: '',
                year: ''
            },
            errors: []
        };
    },
    computed: {
        isFormValid() {
            this.errors = [];

            if (!this.form.civility) this.errors.push('Civilité requise.');
            if (!this.form.firstName) this.errors.push('Prénom requis.');
            if (!this.form.lastName) this.errors.push('Nom requis.');
            if (!this.form.email) this.errors.push('Email requis.');
            if (this.form.email !== this.form.confirmEmail) this.errors.push('Les emails ne correspondent pas.');
            if (!this.form.password) this.errors.push('Mot de passe requis.');
            if (this.form.password !== this.form.confirmPassword) this.errors.push('Les mots de passe ne correspondent pas.');
            if (!this.form.day || !this.form.month || !this.form.year) this.errors.push('Date de naissance requise.');

            return this.errors.length === 0;
        }
    },
    methods: {
        async createAccountData() {
            if (this.isFormValid) {
                try {
                    const response = await api.post('/register', {
                        civility: this.form.civility,
                        first_name: this.form.firstName,
                        last_name: this.form.lastName,
                        email: this.form.email,
                        phone_number: this.form.phoneNumber,
                        password: this.form.password,
                        birthdate: `${this.form.year}-${this.form.month}-${this.form.day}`,
                    });
                    alert('Inscription validée avec succès ! Connectez-vous pour accéder à votre compte');
                    console.log(response.data.message);
                    this.$router.push({ name: 'login' });
                } catch (error) {
                    console.error('Erreur lors de la création du compte:', error.response?.data?.message || error.message);
                }
            } else {
                console.log("Veuillez remplir tous les champs requis.");
                console.log(this.errors.join("\n"));
            }
        }
    }
};
</script>

<template>
    <div class="container my-5">
        <div class="row">
            <div class="col-xl-6 offset-xl-3 col-md-10 offset-md-1 register">
                <h1 class="text-center text-uppercase fw-bold my-5">créez votre compte</h1>
                <p class="fs-6 mb-5">Cet espace vous permet de gérer vos informations personnelles, vos commandes en
                    ligne,
                    votre
                    abonnement aux newsletters et flux d’actualités.</p>
                <form @submit.prevent="createAccountData">
                    <h2>Civilité *</h2>
                    <div class="d-flex civility">
                        <div class="me-5">
                            <input v-model="form.civility" id="civility_mme" type="radio" value="Mme">
                            <label for="civility_mme">Mme</label>
                        </div>
                        <div>
                            <input v-model="form.civility" id="civility_m" type="radio" value="M">
                            <label for="civility_m">M.</label>
                        </div>
                    </div>
                    <div>
                        <label for="firstName">Prénom *</label>
                        <input v-model="form.firstName" id="firstName" type="text" placeholder required />
                    </div>
                    <div>
                        <label for="lastName">Nom *</label>
                        <input v-model="form.lastName" id="lastName" type="text" placeholder required />
                    </div>
                    <div>
                        <label for="email">Adresse email *</label>
                        <input v-model="form.email" id="email" type="email" placeholder required />
                    </div>
                    <div>
                        <label for="confirmEmail">Confirmation de l'adresse email *</label>
                        <input v-model="form.confirmEmail" id="confirmEmail" type="email" placeholder required />
                    </div>
                    <div>
                        <label for="phoneNumber">N° de téléphone</label>
                        <input v-model="form.phoneNumber" data-val-regex-pattern="^[0-9]{5,20}$" id="phoneNumber"
                            type="tel" />
                    </div>
                    <div>
                        <label for="lastName">Mot de passe *</label>
                        <input v-model="form.password" id="profile_password" type="password" placeholder required />
                    </div>
                    <div>
                        <label for="lastName">Confirmer le mot de passe *</label>
                        <input v-model="form.confirmPassword" id="profile_confirm_password" type="password" placeholder
                            required />
                    </div>
                    <h2>Date de naissance *</h2>
                    <div class="d-flex">
                        <div class="born_input">
                            <select v-model="form.day" name="day" id="day">
                                <option value="" disabled>Jour</option>
                                <option v-for="d in 31" :key="d" :value="String(d).padStart(2, '0')">{{
                    String(d).padStart(2, '0') }}</option>
                            </select>
                        </div>
                        <div class="born_input">
                            <select v-model="form.month" name="month" id="month">
                                <option value="" disabled>Mois</option>
                                <option v-for="m in 12" :key="m" :value="String(m).padStart(2, '0')">{{
                    String(m).padStart(2, '0') }}</option>
                            </select>
                        </div>
                        <div class="born_input">
                            <select v-model="form.year" name="year" id="year">
                                <option value="" disabled>Année</option>
                                <option v-for="y in 100" :key="2023 - y" :value="2023 - y">{{ 2023-y }}</option>
                            </select>
                        </div>
                    </div>
                    <p class="mt-5 fs-6">Pour plus de renseignements sur la façon dont nous utilisons vos
                        données à
                        caractère personnel, veuillez consulter notre <a href="">Politique de
                            Confidentialité.</a>
                    </p>

                    <div class="text-center">
                        <button type="submit" id="" class="mt-4 text-uppercase mb-5">créer mon
                            compte</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

</template>

<style scoped>
.register,
input,
.born_input select,
button {
    background-color: #ddd;
}

.register {
    padding-left: 50px;
    padding-right: 50px; 
}

@media (min-width: 768px) { /* > col-md */
    .register {
        padding-left: 100px;
        padding-right: 100px; 
  }
  button {
    width: 300px; 
  }
}

h1,
h2,
p,
a,
label,
input {
    font-family: 'Arapey', serif;
}

h2,
label {
    color: grey;
    font-size: 15px;
    margin-top: 20px;
}

.civility label {
    margin-top: 5px;
}

a {
    color: black;
    text-decoration: underline;
}

a:hover {
    text-decoration: none;
}

input {
    border: 0;
    border-bottom: 1px solid #000;
    font-size: 18px;
    margin-bottom: 20px;
    width: 100%;
}

input[type="radio"] {
    width: auto;
    margin-right: 5px;
}

.born_input {
    flex-basis: 33.33333333333%;
}

.born_input select {
    min-width: 100%;
    min-height: 30px;
    border-top: none;
}

button {
    min-height: 50px;
    border: 2px solid rgb(227, 167, 14);
    font-weight: bold;
    font-size: 20px;
    color: black !important;
}

button:hover {
    background-color: rgb(227, 167, 14);
    color: white;
}
</style>