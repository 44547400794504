import axios from 'axios';

const api = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true
});

api.interceptors.request.use((config) => {
    console.log('Making request to:', config.baseURL + config.url);
    const token = localStorage.getItem('token'); // Récupère le token du stockage local
    if (token) {
        config.headers.Authorization = `Bearer ${token}`; // Ajoute le token à l'en-tête
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default api;
