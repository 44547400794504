<script>
import api from '@/utils/axios';

export default {
    data() {
        return {
            password: '',
            password_confirmation: '',
            token: this.$route.query.token,
            email: this.$route.query.email
        };
    },
    methods: {
        async resetPassword(event) {
            event.preventDefault();
            try {
                const response = await api.post('/password/reset', {
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                    token: this.token,
                });

                alert(response.data.message);
                this.$router.push({ name: 'login' });
            } catch (error) {
                console.error("Erreur lors de la réinitialisation:", error.response.data);
                alert("Erreur lors de la réinitialisation du mot de passe.");
            }
        }
    }
};
</script>

<template>
    <div class="container my-5">
        <div class="row">
            <div class="col-xl-6 offset-xl-3 col-md-10 offset-md-1 resetpassword">
                <h1 class="text-center text-uppercase fw-bold my-5">Réinitialiser votre mot de passe</h1>
                <form @submit.prevent="resetPassword">
                    <div class="mb-3">
                        <label for="password">Nouveau mot de passe *</label>
                        <input type="password" v-model="password" id="password" required />
                    </div>
                    <div class="mb-3">
                        <label for="password_confirmation">Confirmer le mot de passe *</label>
                        <input type="password" v-model="password_confirmation" id="password_confirmation" required />
                    </div>
                    <div class="text-center">
                        <button type="submit" class="mt-4 text-uppercase w-75 mb-5">Réinitialiser</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style scoped>
.resetpassword,
input,
button {
    background-color: #ddd;
}

.resetpassword {
    padding-left: 50px;
    padding-right: 50px;
}

@media (min-width: 768px) {

    /* > col-md */
    .resetpassword {
        padding-left: 100px;
        padding-right: 100px;
    }

    button {
        width: 300px;
    }
}

h1,
label,
a {
    font-family: 'Arapey', serif;
}

label {
    color: grey;
    font-size: 15px;
    margin-top: 20px;
}

input {
    border: 0;
    border-bottom: 1px solid #000;
    font-family: 'Arapey', serif;
    font-size: 18px;
    margin-bottom: 20px;
    width: 100%;
}

button {
    min-height: 50px;
    border: 2px solid rgb(227, 167, 14);
    font-weight: bold;
    font-size: 20px;
    color: black !important;
}

button:hover {
    background-color: rgb(227, 167, 14);
    color: white;
}
</style>
