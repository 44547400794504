<script>
import api from '@/utils/axios';

export default {
    props: {
        carouselImages: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            homeProducts: [],
        };
    },

    mounted() {
        this.fetchHomepageProducts();
    },

    methods: {
        async fetchHomepageProducts() {
            try {
                const response = await api.get('/homepage');
                this.homeProducts = response.data;
            } catch (error) {
                console.error('Erreur lors de la récupération des produits en vedette', error);
            }
        },
    },
};
</script>

<template>
    <main>
        <div class="container">
            <div class="row mt-5">
                <div v-for="(homeProduct, index) in homeProducts" :key="homeProduct.id"
                    :class="{ 'col-md-10 offset-1 col-12': index % 3 === 0, 'col-md-5 offset-1 col-12': index % 3 !== 0 }">
                    <div class="product text-center">
                        <router-link :to="{ name: 'detailproduct', params: { id: homeProduct.id } }">
                            <img :src="homeProduct.photo_url" alt="">
                            <h1 class="fw-normal text-uppercase my-0 fs-1">{{ homeProduct.name }}</h1>
                            <p class="mt-0 fs-6 fst-italic">Découvrir la collection</p>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<style scoped>
h1,
p {
    font-family: 'Arapey', serif;
}
h1 {
    color: #000;
}
p {
    color: rgb(235, 163, 39);
}
p:hover {
    text-decoration: underline;
}
.product {
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
    height: auto;
}
</style>