<script>
import api from '@/utils/axios';

export default {
    data() {
        return {
            email: '',
        };
    },
    methods: {
        async sendResetLink() {
            try {
                const response = await api.post('/password/forgot', {
                    email: this.email,
                });
                alert('Un lien de réinitialisation a été envoyé à votre adresse email.');
            } catch (error) {
                console.error('Erreur lors de l\'envoi du lien de réinitialisation:', error);
                alert('Erreur lors de l\'envoi du lien. Veuillez réessayer.');
            }
        },
    },
};
</script>

<template>
    <div class="container my-5">
        <div class="row">
            <div class="col-xl-6 offset-xl-3 col-md-10 offset-md-1 forgetpassword">
                <h1 class="text-center text-uppercase fw-bold my-5">Réinitialiser votre mot de passe</h1>
                <p class="fs-6 mb-4">Veuillez entrer votre adresse email. Un lien de réinitialisation vous sera
                    envoyé.</p>
                <form @submit.prevent="sendResetLink">
                    <label for="email">Adresse Email *</label>
                    <input v-model="email" type="email" id="email" placeholder required />
                    <p class="mt-4 fs-6">Pour plus d'informations sur la gestion de vos données personnelles,
                        consultez notre <a href="">Politique de confidentialité</a>.</p>
                    <div class="text-center">
                        <button type="submit" class="mt-4 text-uppercase mb-5">Envoyer le lien</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>


<style scoped>
.forgetpassword,
input,
button {
    background-color: #ddd;
}

.forgetpassword {
    padding-left: 50px;
    padding-right: 50px;
}

@media (min-width: 768px) {

    /* > col-md */
    .forgetpassword {
        padding-left: 100px;
        padding-right: 100px;
    }

    button {
        width: 300px;
    }
}

h1,
p,
label,
a {
    font-family: 'Arapey', serif;
}

label {
    color: grey;
    font-size: 15px;
    margin-top: 20px;
}

a {
    color: black;
    text-decoration: underline;
}

a:hover {
    text-decoration: none;
}

input {
    border: 0;
    border-bottom: 1px solid #000;
    font-family: 'Arapey', serif;
    font-size: 18px;
    margin-bottom: 20px;
    width: 100%;
}

button {
    min-height: 50px;
    border: 2px solid rgb(227, 167, 14);
    font-weight: bold;
    font-size: 20px;
}

button:hover {
    background-color: rgb(227, 167, 14);
    color: white;
}
</style>
