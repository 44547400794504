<script>
import api from '@/utils/axios';
import { loadStripe } from '@stripe/stripe-js';
import AddressForm from "../components/AddressForm.vue";

export default {
    components: {
        AddressForm,
    },
    data() {
        return {
            userProfile: null,
            cartProducts: [],
            stripe: null,
            card: null,
            clientSecret: null,
            isPaymentProcessing: false,
            errorMessage: null,
        };
    },
    async created() {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('user_id');
        const response = await api.get(`/profiles/${userId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        this.userProfile = response.data;

        try {
            const stripeKeyResponse = await api.get('/stripe-key');
            this.stripe = await loadStripe(stripeKeyResponse.data.key);
            const elements = this.stripe.elements();
            this.card = elements.create('card');
            this.card.mount('#card-element');

            const paymentIntentResponse = await api.post('/payment-intent');
            this.clientSecret = paymentIntentResponse.data.clientSecret;

            await this.fetchCartData();
        } catch (error) {
            console.error('Erreur lors de la création du PaymentIntent:', error);
            this.errorMessage = "Une erreur est survenue lors de l'initialisation du paiement. Veuillez réessayer.";
        }
    },
    methods: {
        async fetchCartData() {
            try {
                const response = await api.get('/cart');
                this.cartProducts = Array.isArray(response.data.cartItems) ? response.data.cartItems : [];

                for (let i = 0; i < this.cartProducts.length; i++) {
                    const productId = this.cartProducts[i].product_id;
                    const productResponse = await api.get(`/products/${productId}`);
                    const productDetails = productResponse.data;

                    this.cartProducts[i].photo_url = productDetails.photo_url;
                    this.cartProducts[i].price = productDetails.price;
                    this.cartProducts[i].totalLine = (this.cartProducts[i].price * this.cartProducts[i].quantity).toFixed(2);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération du panier:', error);
            }
        },
        handleAddressSaved() {
            window.location.reload();
        },
        async initiatePayment() {
            if (!this.clientSecret) {
                console.error('Le client_secret est manquant.');
                this.errorMessage = "Un problème est survenu avec le paiement. Veuillez réessayer.";
                return;
            }

            this.isPaymentProcessing = true;
            this.errorMessage = null;

            try {
                // Tenter de passer la commande
                const orderResponse = await this.placeOrder();

                // Si la commande est réussie, procéder au paiement
                const { error, paymentIntent } = await this.stripe.confirmCardPayment(this.clientSecret, {
                    payment_method: {
                        card: this.card,
                    },
                });

                if (error) {
                    console.error('Erreur de paiement:', error);
                    this.errorMessage = error.message || "Une erreur inattendue est survenue. Veuillez réessayer.";
                } else if (paymentIntent.status === 'succeeded') {
                    // Confirmer le paiement
                    await api.post('/payment-confirmation', {
                        transaction_id: paymentIntent.id,
                        amount: paymentIntent.amount / 100,
                        status: paymentIntent.status,
                    });

                    alert('Paiement et commande effectués avec succès!');
                    this.$router.push({ name: 'home' });
                } else {
                    console.error('Le paiement n\'a pas été réussi:', paymentIntent.status);
                    this.errorMessage = "Le paiement n'a pas été validé. Veuillez réessayer.";
                }
            } catch (error) {
                console.error('Erreur lors du processus de paiement ou de commande:', error);
                this.errorMessage = error.message || "Une erreur s'est produite. Veuillez réessayer.";
            } finally {
                this.isPaymentProcessing = false;
            }
        },
        async placeOrder() {
            const shippingAddressId = this.$refs.shippingForm.form.id;
            const billingAddressId = this.$refs.billingForm.form.id;

            if (!shippingAddressId || !billingAddressId) {
                throw new Error("Veuillez sélectionner une adresse de livraison et une adresse de facturation.");
            }

            try {
                const response = await api.post('/order', {
                    shipping_address_id: shippingAddressId,
                    billing_address_id: billingAddressId,
                });

                alert('Commande passée avec succès !');
                return response;
            } catch (error) {
                console.error('Erreur lors de la création de la commande:', error);
                throw new Error('Une erreur s\'est produite lors du passage de la commande.');
            }
        },
    },
    computed: {
        totalCart() {
            const total = this.cartProducts.reduce((sum, product) => {
                return sum + parseFloat(product.totalLine || 0);
            }, 0);
            return Number(total.toFixed(2));
        },
    }

};
</script>

<template>
    <div class="container">
        <div class="row">
            <div class="col-6">
                <h2>1 - INFORMATIONS DE CONNEXION</h2>
                <hr>
                <p>Vous commandez avec cette adresse e-mail : <span>{{ userProfile.email }}</span></p>
                <h2>2 - ADRESSE DE LIVRAISON</h2>
                <hr>
                <AddressForm ref="shippingForm" type="shipping" @address-saved="handleAddressSaved" />
                <h2>3 - PAIEMENT</h2>
                <h3>ADRESSE DE FACTURATION</h3>
                <hr>
                <AddressForm ref="billingForm" type="billing" @address-saved="handleAddressSaved" />
            </div>

            <div class="col-5 offset-1 recap_order">
                <h2 class="text-uppercase">Récapitulatif de commande</h2>
                <hr>
                <div v-for="product in cartProducts" :key="product.id" class="order_item">
                    <img :src="product.photo_url" alt="Image produit" class="product_img">
                    <div>
                        <p class="product_detail">{{ product.product_name }}</p>
                        <p class="product_detail">{{ product.price }} €</p>
                    </div>
                    <hr>
                </div>
                <div class="summary">
                    <div class="summary_item">
                        <span>SOUS-TOTAL</span>
                        <span>{{ totalCart }} €</span>
                    </div>
                    <div class="summary_item">
                        <span>TVA</span>
                        <span>{{ (totalCart * 0.2).toFixed(2) }} €</span>
                    </div>
                    <div class="summary_item">
                        <span>OPTIONS DE LIVRAISON</span>
                        <span>Offerte</span>
                    </div>
                    <hr>
                    <div class="summary_item total">
                        <span>TOTAL</span>
                        <span>{{ (totalCart).toFixed(2) }} €</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <h3 class="text-uppercase details_payment">Détails du paiement</h3>
            <hr>
            <p class="text-uppercase secure_payment">Paiements sécurisés</p>
            <div class="col-8 offset-2">
                <form @submit.prevent="initiatePayment">
                    <div id="card-element"></div>
                    <div class="button-container">
                        <button type="submit" :disabled="isPaymentProcessing">
                            COMMANDER
                        </button>
                    </div>
                </form>
                <div v-if="errorMessage" class="alert alert-danger mt-3">
                    {{ errorMessage }}
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.container {
    height: 100%;
}

p,
a {
    font-family: 'Arapey', serif;
}

h2,
h3 {
    color: rgb(83, 83, 83);
    font-weight: bold;
}

h2 {
    margin-top: 50px;
    font-size: 26px;
}

h3 {
    margin-top: 20px;
    font-size: 24px;
}

.details_payment {
    margin-top: 50px;
}

p {
    font-size: 18px;
    margin-top: 20px;
}

.secure_payment {
    font-size: 20px;
    margin-top: 20px;
    color: grey;
}

span {
    font-size: 18px;
    font-weight: bold;
}

#card-element {
    margin-top: 30px;
    padding: 40px;
    border: 2px solid grey;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

button {
    min-height: 50px;
    border: 2px solid rgb(227, 167, 14);
    background-color: white;
    font-weight: bold;
    font-size: 20px;
    width: 200px;
    color: black;
}

@media (min-width: 768px) {

    /* > col-md */
    button {
        width: 300px;
    }
}

button:hover {
    background-color: rgb(227, 167, 14);
    color: white;
}

.recap_order {
    position: sticky;
    top: 20px;
    align-self: flex-start;
}

.order_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 100px 0;
}

.product_img {
    height: 160px;
    width: auto;
}

.product_detail {
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
}

.summary {
    border: 2px solid rgb(227, 167, 14);
    padding: 15px;
    margin-top: auto;
}

.summary_item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 16px;
}
</style>