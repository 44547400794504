<script>
import api from '@/utils/axios';

export default {
    props: {
        category: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        carouselImages: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            productsBeforeFilters: [],
            products: [],
            sortByPrice: null,
            selectedGender: null,
            showFilters: false,
        };
    },
    mounted() {
        console.log('Mounted: fetching products');
        this.fetchProducts();
    },
    methods: {
        async fetchProducts() {
            try {
                const response = await api.get(`/products/category/${this.category}`);
                this.productsBeforeFilters = response.data;
                this.products = this.productsBeforeFilters;
            } catch (error) {
                console.error(`Erreur lors de la récupération des produits pour ${this.category}`, error);
            }
        },
        productsByPrice() {
            if (this.sortByPrice === 'crescent') {
                this.products.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
            } else if (this.sortByPrice === 'descending') {
                this.products.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
            } else {
                this.products = this.productsBeforeFilters;
            }
        },
        productsByGender() {
            if (this.selectedGender === "female") {
                this.products = this.productsBeforeFilters.filter(product => product.gender === "female");
            } else if (this.selectedGender === "male") {
                this.products = this.productsBeforeFilters.filter(product => product.gender === "male");
            } else if (this.selectedGender === "unisex") {
                this.products = this.productsBeforeFilters.filter(product => product.gender === "unisex");
            } else {
                this.products = this.productsBeforeFilters;
            }
        },
        resetFilters() {
            this.sortByPrice = null;
            this.selectedGender = null;
            this.products = this.productsBeforeFilters;
        },
        limitText(text, length) {
            if (text.length <= length) {
                return text;
            }
            return text.substring(0, length) + '...';
        },
        hideFilters() {
            this.showFilters = !this.showFilters;
        }
    },
    watch: {
        sortByPrice: 'productsByPrice',
        selectedGender: 'productsByGender',
    },
};
</script>

<template>
    <main>
        <div class="container">
            <div class="row mt-3">
                <div :class="showFilters ? 'col-xl-2 col-12 filters' : ' col-xl-1 col-12 filters'">
                    <button @click="hideFilters" class="burger-btn">
                        <i :class="showFilters ? 'fa-solid fa-xmark' : 'fa-solid fa-arrow-down-wide-short'"></i>
                    </button>

                    <div v-if="showFilters" class="mt-3">
                        <h1>TRIER PAR</h1>
                        <div class="d-flex flex-column">
                            <div>
                                <input type="radio" name="order_by" id="prix_croissant_option" value="crescent"
                                    v-model="sortByPrice">
                                <label for="prix_croissant_option">Prix croissant</label>
                            </div>
                            <div>
                                <input type="radio" name="order_by" id="prix_decroissant_option" value="descending"
                                    v-model="sortByPrice">
                                <label for="prix_decroissant_option">Prix décroissant</label>
                            </div>
                        </div>
                        <hr>
                        <h1>FILTRER PAR</h1>
                        <div class="d-flex flex-column">
                            <div>
                                <input type="radio" name="filter_option" id="femme_option" value="female"
                                    v-model="selectedGender">
                                <label for="femme_option">Femme</label>
                            </div>
                            <div>
                                <input type="radio" name="filter_option" id="homme_option" value="male"
                                    v-model="selectedGender">
                                <label for="homme_option">Homme</label>
                            </div>
                            <div>
                                <input type="radio" name="filter_option" id="unisex_option" value="unisex"
                                    v-model="selectedGender">
                                <label for="unisex_option">Unisexe</label>
                            </div>
                        </div>
                        <button v-if="sortByPrice || selectedGender" @click="resetFilters"
                            class="btn mt-3 border">Supprimer les filtres</button>
                    </div>

                </div>

                <div :class="showFilters ? 'col-xl-10 col-12' : 'col-12'">
                    <div class="row mt-3">
                        <div class="col-12">
                            <p>Nombre d'articles : {{ products.length }}</p>
                            <!-- Carrousel -->
                            <div id="carouselProducts" class="carousel slide mb-5" data-bs-ride="carousel">
                                <div class="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselProducts" data-bs-slide-to="0"
                                        class="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselProducts" data-bs-slide-to="1"
                                        aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselProducts" data-bs-slide-to="2"
                                        aria-label="Slide 3"></button>
                                </div>
                                <div class="carousel-inner">
                                    <div class="carousel-item active" data-bs-interval="5000">
                                        <img :src="carouselImages[0]" class="d-block w-100" alt="">
                                    </div>
                                    <div class="carousel-item">
                                        <img :src="carouselImages[1]" class="d-block w-100" alt="">
                                    </div>
                                    <div class="carousel-item">
                                        <img :src="carouselImages[2]" class="d-block w-100" alt="">
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselProducts"
                                    data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselProducts"
                                    data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-4">
                    <div v-for="product in products" :key="product.id"
                        class="col-xl-4 col-md-6 col-12 my-5">
                        <div class="products mx-auto w-100 text-center">
                            <router-link :to="{ name: 'detailproduct', params: { id: product.id } }">
                                <img :src="product.photo_url" alt="Image du produit" class="product_img img-fluid"
                                    v-if="product.photo_url">
                            </router-link>
                            <h2 class="my-0 fw-bold fs-5 text-uppercase">{{ product.name }}</h2>
                            <p class="fst-italic">{{ limitText(product.description, 80) }}</p>
                            <h2>€ {{ product.price }}</h2>
                            <div class="mt-3">
                                <router-link :to="{ name: 'detailproduct', params: { id: product.id } }">
                                    <button class="buy_btn w-75 rounded-pill fs-5">ACHETER</button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </main>
</template>

<style scoped>
p,
h1 {
    font-family: 'Arapey', serif;
}

p {
    font-size: 16px;
}

h2 {
    font-weight: 600;
    font-size: 20px;
}

.filters h1 {
    font-size: 25px;
}

.filters .d-flex.flex-column>div {
    margin-bottom: 10px;
    margin-left: 20px;
    font-family: 'Arapey', serif;
}

.filters input[type="radio"] {
    display: none;
}

.filters input[type="radio"]+label {
    position: relative;
    padding-left: 30px;
}

.filters input[type="radio"]+label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 15px;
    height: 15px;
    border: 1px solid rgb(227, 167, 14);
    background-color: #fff;
}

.filters input[type="radio"]:checked+label::before {
    background-color: rgb(227, 167, 14);
    border-color: rgb(227, 167, 14);
}

.product_img {
    min-height: 320px;
}

.products h1 {
    font-size: 20px;
}

.products p {
    font-size: 10px;
    color: rgb(235, 163, 39);
}

.buy_btn {
    display: none;
}

.products:hover .buy_btn {
    background-color: black;
    color: white;
    font-weight: bold;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.products .buy_btn:hover {
    background-color: white;
    color: black;
}

.burger-btn {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-bottom: 10px;
    color: black !important;
}
</style>
