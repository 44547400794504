<script>
import api from '@/utils/axios';

export default {
    data() {
        return {
            showNavUser: false,
        };
    },
    methods: {
        hideNavUser() {
            this.showNavUser = !this.showNavUser;
        },
        async logout() {
            try {
                const token = localStorage.getItem('token');
                if (token) {
                    await api.post('/logout', {}, {
                        headers: { 'Authorization': `Bearer ${token}` },
                    });
                }

                localStorage.removeItem('token');
                localStorage.removeItem('user_id');
                this.$router.push({ name: 'login' });
            } catch (error) {
                console.error("Erreur lors de la déconnexion:", error);
            }
        },
    },
};
</script>

<template>
    <div class="container">
        <div class="row">
            <div class="col-xl-2 offset-xl-1 col-md-4 col-12">
                <button @click="hideNavUser" class="burger-btn">
                    <i :class="showNavUser ? 'fa-solid fa-xmark' : 'fa-solid fa-bars'"></i>
                </button>

                <div v-if="showNavUser" class="nav-section">
                    <div class="d-flex flex-column mt-3">
                        <nav>
                            <ul>
                                <li><router-link :to="{ name: 'insight' }">aperçu</router-link></li>
                                <li><router-link :to="{ name: 'profile' }">mon profil</router-link></li>
                                <li><router-link :to="{ name: 'myorder' }">mes commandes</router-link></li>
                                <li><router-link :to="{ name: 'address' }">mes adresses</router-link></li>
                                <li><router-link :to="{ name: 'wishlist' }">ma wishlist</router-link></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>

            <div :class="showNavUser ? 'col-xl-8 col-md-8 col-12 mt-5' : 'col-xl-10 offset-xl-1 col-12'">
                <div class="row flex-column align-items-center text-center welcome">
                    <h2 class="mt-5 mb-4 fw-bold">BIENVENUE USER</h2>
                    <p class="fs-6 w-75">
                        À partir de votre compte Du Sablon, vous pouvez gérer et garder la trace de votre expérience
                        d’achat
                        personnalisée sur la boutique en ligne Du Sablon.
                    </p>
                    <button @click="logout" class="mt-4 mb-5 text-uppercase fw-bold" id="deconnect">déconnexion</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.welcome {
    background-color: rgb(236, 236, 236);
}

h1,
h2,
p {
    font-family: 'Arapey', serif;
}

#deconnect {
    min-height: 50px;
    border: 2px solid rgb(227, 167, 14);
    background-color: rgb(236, 236, 236);
    font-size: 20px;
    width: 200px;
    color: black !important;
}

@media (min-width: 768px) { /* > col-md */
    #deconnect {
    width: 300px; 
  }
}

#deconnect:hover {
    background-color: rgb(227, 167, 14);
    color: white;
}

.burger-btn {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-bottom: 10px;
    color: black !important;
}

h1 {
    font-weight: 600;
    font-size: 30px;
}

li {
    color: black;
    font-weight: 600;
    margin-bottom: 20px;
    text-transform: uppercase;
}

a {
    color: black;
    font-weight: 600;
    font-size: 20px;
    transition: font-size 0.5s ease-in-out;
}

a:hover {
    text-decoration: underline;
    text-decoration-color: rgb(227, 167, 14);
    text-decoration-thickness: 0.2rem;
    text-underline-offset: 8px;
}
</style>