<script>
import api from '@/utils/axios';

export default {
    data() {
        return {
            isAuthenticated: false,
            product: {},
        };
    },
    mounted() {
        this.fetchProductDetails(this.$route.params.id);
    },
    methods: {
        checkAuthentication() {
            const token = localStorage.getItem('token');
            this.isAuthenticated = !!token;
        },
        async fetchProductDetails(productId) {
            try {
                const response = await api.get(`/products/${productId}`);
                this.product = response.data;

                const relatedResponse = await api.get(`/products/${productId}/related`);
                this.product.relatedProducts = relatedResponse.data;
                console.log(this.product.relatedProducts)
            } catch (error) {
                console.error(`Erreur lors de la récupération du produit:`, error);
            }
        },
        async addToCart() {
            if (!this.isAuthenticated) {
                this.$router.push({ name: 'login' });
            } else {
                try {
                    const response = await api.post('/cart', {
                        product_id: this.product.id,
                        quantity: 1,
                    });
                    console.log('Produit ajouté au panier avec succès!');
                    this.$router.push({ name: 'cart' });
                } catch (error) {
                    console.error('Erreur lors de l\'ajout au panier:', error);
                    console.log('Une erreur est survenue lors de l\'ajout du produit au panier.');
                }
            }
        }
    },
    created() {
        this.checkAuthentication();
    },
    watch: {
        '$route.params.id': function () {
            this.fetchProductDetails(this.$route.params.id);
        }
    }
};
</script>

<template>
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-12">
                <img :src="product.photo_url" alt="Image du produit" class="img-fluid" v-if="product.photo_url">
                <p v-else>Aucune image disponible pour ce produit.</p>
            </div>
            <div class="col-xl-5 col-10 offset-1 mt-5 d-flex flex-column">
                <h1 class="fw-bold">{{ product.name }}</h1>
                <p class="fs-6 order-4 order-md-1">{{ product.description }}</p>
                <h2 class="mb-4 fw-bold order-1 order-md-2">€ {{ product.price }} <span class="fs-6 fw-light">incl. TVA</span></h2>
                <p v-if="product.stock === 0" class="text-danger fw-bold order-2 order-md-3">Rupture de stock</p>
                <div class="add_cart_div order-3 order-md-4">
                    <button @click="addToCart" class="add_cart_button w-100 rounded-pill mb-4 py-1" :disabled="product.stock === 0">AJOUTER AU
                        PANIER</button>
                </div>
                <!-- <div class="w-50 d-flex justify-content-between">
                    <i class="fa-solid fa-share-nodes"></i>
                    <h4 class="fs-6">PARTAGER</h4>
                    <span class="vertical-line2"></span>
                    <p>Ref. {{ product.ref }}</p>
                </div> -->
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-md-3 img_product">
                <img :src="product.path_img2" alt="">
            </div>
            <div class="col-md-3 img_product">
                <img :src="product.path_img3" alt="">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 img_product">
                <img :src="product.path_img4" alt="">
            </div>
        </div> -->
        <div v-if="product.relatedProducts && product.relatedProducts.length > 0" class="row text-center mt-5">
            <h1 class="text-uppercase">Créations Associées</h1>
            <p class="fs-5">Découvrez les autres créations uniques</p>
            <div class="col-xl-4 col-md-6 col-12" v-for="relatedProduct in product.relatedProducts" :key="relatedProduct.id">
                <router-link :to="{ name: 'detailproduct', params: { id: relatedProduct.id } }">
                    <img :src="relatedProduct.photo_url" class="img-fluid" alt="Image du produit associé">
                    <h2>{{ relatedProduct.name }}</h2>
                    <h3 class="fw-bold mb-4">€ {{ relatedProduct.price }}</h3>
                </router-link>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-4 col-md-5 col-10 offset-1 p-5 mt-4 border-end border-5 border-white greydiv">
                <h5>LIVRAISON / RETOUR</h5>
                <p>Nous proposons différents modes de livraison. Choisissez celui que vous préférez. vous pouvez
                    retourner
                    votre article Du sablon dans un délai de 15 jours.</p>
            </div>
            <div class="col-xl-4 offset-xl-2 col-md-5 col-10 offset-1 offset-md-0 p-5 mt-4 greydiv">
                <h5>EMBALLAGES CADEAUX</h5>
                <p>Envoyez vos cadeaux dans notre emballage spécial accompagné d’une carte personnalisée.</p>
            </div>
        </div>

        <div class="row d-flex my-5">
            <div class="col-md-4 col-10 offset-1 offset-md-0 arguments">
                <i class="fa-solid fa-truck-fast"></i>
                <h1>LIVRAISON OFFERTE</h1>
            </div>
            <div class="col-md-4 col-10 offset-1 offset-md-0 arguments">
                <i class="fa-solid fa-gift"></i>
                <h1>EMBALLAGES CADEAUX</h1>
            </div>
            <div class="col-md-4 col-10 offset-1 offset-md-0 arguments">
                <i class="fa-solid fa-right-left"></i>
                <h1>RETOUR FACILE</h1>
            </div>
        </div>
    </div>
</template>

<style scoped>
h1,
p,
h2,
h3,
h4,
h5 {
    font-family: 'Arapey', serif;
}
a {
    color: #000;
}
button {
    background-color: #000;
    color: white;
    font-size: 22px;
    font-weight: bold;
}
button:hover {
    background-color: white;
    color: #000;
}
.vertical-line2 {
    border: 1px solid #000;
    height: 20px;
}
.greydiv {
    background-color: rgb(236, 236, 236);
}
h5 {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}
.arguments {
    text-align: center;
    flex-direction: column;
}
i {
    display: block;
    margin: 40px auto;
    font-size: 25px;
    color: rgb(227, 167, 14);
}
@media (min-width: 576px) {
    /* > col-md */
    i {
        font-size: 40px;
        margin: 40px auto;
    }
}
.arguments h1 {
    font-size: 15px;
    font-weight: 600;
}
@media (min-width: 576px) {
    .arguments h1 {
        font-size: 25px;
    }
}
</style>