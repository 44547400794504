<script>
import UserPanel from "../components/UserPanel.vue"
import api from "@/utils/axios";

export default {
    components: {
        UserPanel,
    },
    data() {
        return {
            orders: [],
        };
    },
    mounted() {
        this.fetchUserOrders();
    },
    methods: {
        async fetchUserOrders() {
            try {
                const response = await api.get('/orders/user');
                this.orders = response.data.orders;
                console.log(response.data.orders);
            } catch (error) {
                console.error("Erreur lors de la récupération des commandes:", error);
            }
        },
    },
};
</script>

<template>
    <div class="container">
        <div class="row">
            <UserPanel></UserPanel>

            <div class="col-xl-8 offset-xl-2 col-md-10 offset-md-1 col-12">
                <h3 class="mt-5 fw-bold">Mes commandes</h3>
                <p class="mt-3">Affichez les détails de votre commande. Si vous souhaitez retourner ou échanger
                    un
                    article, vous
                    pourrez contacter notre Service Client pour demander un numéro d’autorisation de retour une
                    fois
                    que
                    votre commande sera livrée.</p>

                <div v-if="orders.length > 0" class="table-responsive">
                    <table class="table table-hover">
                        <thead class="table-dark text-center">
                            <tr>
                                <!-- <th scope="col">N°</th> -->
                                <th scope="col">Date</th>
                                <th scope="col">Articles</th>
                                <th scope="col">Montant Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="order in orders" :key="order.id">
                                <!-- <td scope="row" class="text-center">{{ order.id }}</td> -->
                                <td class="text-center">{{ new Date(order.created_at).toLocaleDateString() }}
                                </td>
                                <td>
                                    <ul class="list-unstyled mb-0 ms-3 fw-bold">
                                        <li v-for="item in order.order_items" :key="item.id">
                                            {{ item.product_name }} x {{ item.quantity }}
                                        </li>
                                    </ul>
                                    <router-link :to="{ name: 'orderdetail', params: { id: order.id } }"
                                        class="btn btn-link ms-2 fst-italic">
                                        Voir le détail
                                    </router-link>
                                </td>
                                <td class="fw-bold text-center">{{ order.total_price }} €</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-else>
                    <p>Vous n'avez pas encore passé de commandes.</p>
                    <router-link class="nav-link" :to="{ name: 'home' }">Continuer votre shopping</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
h3,
p,
a {
    font-family: 'Arapey', serif;
}

p {
    font-size: 16px;
}

ul {
    font-size: 15px;
}

td {
    padding: 20px;
}

a {
    color: black;
    font-size: 16px;
    text-decoration: none;
}

a:hover {
    color: black;
    text-decoration: underline;
    text-decoration-color: rgb(227, 167, 14);
}
</style>