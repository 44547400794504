<script>
import ProductList from '@/components/ProductList.vue';

export default {
    components: {
        ProductList
    }
};
</script>

<template>
    <ProductList
        category="bracelets"
        title="BRACELETS"
        :carouselImages="['/img/bracelet_accueil.jpg', '/img/DuSablonLogoZoom.png', '/img/DuSablonLogoZoom2.png']"
    />
</template>