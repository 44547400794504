<script>
import ProductList from '@/components/ProductList.vue';

export default {
    components: {
        ProductList
    }
};
</script>

<template>
    <ProductList
        category="bagues"
        title="BAGUES"
        :carouselImages="['/public/img/bracelet_accueil.jpg', '/public/img/DuSablonLogoZoom.png', '/public/img/DuSablonLogoZoom2.png']"
    />
</template>