<script>
import api from '@/utils/axios';

export default {
    props: {
        type: { type: String, required: true },
    },
    data() {
        return {
            hasAddress: false,
            form: {
                id: null,
                firstName: '',
                lastName: '',
                address: '',
                streetNumber: '',
                city: '',
                postalCode: '',
                phoneNumber: '',
            },
            errors: {},
        };
    },
    mounted() {
        this.fetchUserAddress();
    },
    computed: {
        isFormValid() {
            this.errors = [];
            if (!this.form.firstName) this.errors.push('Prénom requis.');
            if (!this.form.lastName) this.errors.push('Nom requis.');
            if (!this.form.address) this.errors.push('Adresse requise.');
            if (!this.form.streetNumber) this.errors.push('Numéro de rue requis.');
            if (!this.form.city) this.errors.push('Ville requise.');
            if (!this.form.postalCode) this.errors.push('Code postal requis.');
            if (!this.form.phoneNumber) this.errors.push('Téléphone requis.');
            return this.errors.length === 0;
        },
        fields() {
            return [
                { key: 'firstName', label: 'Prénom', type: 'text' },
                { key: 'lastName', label: 'Nom', type: 'text' },
                { key: 'address', label: 'Adresse', type: 'text' },
                { key: 'streetNumber', label: 'Numéro de rue', type: 'text' },
                { key: 'city', label: 'Ville', type: 'text' },
                { key: 'postalCode', label: 'Code postal', type: 'text' },
                { key: 'phoneNumber', label: 'Téléphone', type: 'tel' },
            ];
        },
    },
    methods: {
        async fetchUserAddress() {
            try {
                const token = localStorage.getItem("token");
                const response = await api.get(`/${this.type}-address/user`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const addresses = response.data.addresses;

                if (addresses.length > 0) {
                    this.hasAddress = true;
                    const address = addresses[0];
                    this.form = {
                        id: address.id,
                        firstName: address.first_name || '',
                        lastName: address.last_name || '',
                        address: address.address || '',
                        streetNumber: address.street_number || '',
                        city: address.city || '',
                        postalCode: address.postal_code || '',
                        phoneNumber: address.phone_number || '',
                    };
                } else {
                    this.hasAddress = false;
                }
            } catch (error) {
                console.error(
                    `Erreur lors de la récupération de l'adresse de ${this.type} :`,
                    error.response?.data || error.message
                );
            }
        },
        async saveAddress() {
            if (this.isFormValid) {
                try {
                    const token = localStorage.getItem('token');
                    const endpoint = this.hasAddress
                        ? `/${this.type}-address/user`
                        : `/${this.type}-address`;
                    const method = this.hasAddress ? 'put' : 'post';

                    const response = await api[method](endpoint, {
                        first_name: this.form.firstName,
                        last_name: this.form.lastName,
                        address: this.form.address,
                        street_number: this.form.streetNumber,
                        city: this.form.city,
                        postal_code: this.form.postalCode,
                        phone_number: this.form.phoneNumber,
                    }, {
                        headers: { Authorization: `Bearer ${token}` }
                    });

                    alert(this.hasAddress
                        ? 'Adresse mise à jour avec succès !'
                        : 'Adresse enregistrée avec succès !');
                    console.log(response.data.message);
                    this.$emit('address-saved');
                } catch (error) {
                    console.error(
                        'Erreur lors de la sauvegarde de l\'adresse:',
                        error.response?.data?.message || error.message
                    );
                }
            } else {
                console.log("Veuillez remplir tous les champs requis.");
                console.log(this.errors.join("\n"));
            }
        },
    },
};
</script>

<template>
    <form @submit.prevent="saveAddress">
        <div v-for="field in fields" :key="field.key" class="mb-1">
            <label :for="field.key">{{ field.label }}</label>
            <input v-model="form[field.key]" :id="field.key" :type="field.type" :placeholder="field.label" required />
            <div v-if="errors[field.key]" class="text-danger">
                {{ errors[field.key] }}
            </div>
        </div>
        <div class="button-container">
            <button type="submit" class="text-uppercase">
                {{ hasAddress ? 'METTRE à JOUR' : 'ENREGISTRER' }}
            </button>
        </div>
    </form>
</template>

<style scoped>
input,
label {
    font-family: 'Arapey', serif;
}

label {
    margin-bottom: 10px;
    margin-top: 20px;
    color: grey;
    font-size: 18px;
}

input {
    border: 0;
    border-bottom: 1px solid #000;
    font-size: 18px;
    margin-bottom: 20px;
    width: 100%;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

button {
    min-height: 50px;
    border: 2px solid rgb(227, 167, 14);
    background-color: white;
    font-weight: bold;
    font-size: 20px;
    width: 200px;
    color: black;
}

@media (min-width: 768px) {

    /* > col-md */
    button {
        width: 300px;
    }
}

button:hover {
    background-color: rgb(227, 167, 14);
    color: white;
}
</style>