<script>
import UserPanel from "../components/UserPanel.vue";
import AddressForm from "../components/AddressForm.vue";

export default {
    components: {
        UserPanel,
        AddressForm,
    },
};
</script>

<template>
    <div class="container">
        <div class="row">
            <UserPanel />
            <div class="col-md-10 offset-md-1">
                <h3 class="fw-bold mt-5">Carnet d'adresses</h3>
                <p class="mt-4 mb-5">
                    Sauvegardez toutes vos adresses de livraison pour effectuer plus rapidement vos achats. <br />
                    Si vous avez une adresse préférée, indiquez-la comme prédéfinie : le champ sera déjà rempli dans votre
                    Panier.
                </p>
            </div>

            <div class="col-md-10 offset-md-1">
                <h4>Adresse de livraison</h4>
                <AddressForm type="shipping" />
            </div>
        </div>
    </div>
</template>


<style scoped>
h3,
p {
    font-family: 'Arapey', serif;
}

p {
    color: black;
    font-size: 16px;
}
</style>