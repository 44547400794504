<script>
import UserPanel from "../components/UserPanel.vue"
import api from "@/utils/axios";

export default {
    components: {
        UserPanel,
    },
    data() {
        return {
            newPassword: "",
            confirmNewPassword: "",
            successMessage: "",
        };
    },
    methods: {
        async updatePassword(event) {
            event.preventDefault();

            if (this.newPassword !== this.confirmNewPassword) {
                this.error = "Les nouveaux mots de passe ne correspondent pas.";
                return;
            }

            try {
                const token = localStorage.getItem("token");
                const userId = localStorage.getItem("user_id");

                if (userId && token) {
                    const response = await api.put(`/profiles/${userId}/update-password`, {
                        password: this.newPassword,
                        password_confirmation: this.confirmNewPassword,
                    }, {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                    );
                    this.successMessage = "Mot de passe mis à jour avec succès!";
                    console.log("Mot de passe mis à jour avec succès!", response.data);
                } else {
                    console.error("ID utilisateur ou token manquant.");
                }
            } catch (error) {
                if (error.response && error.response.data && error.response.data.errors) {
                    console.error("Erreur lors de la mise à jour du profil:", error.response.data.errors);
                } else {
                    console.error("Erreur lors de la mise à jour du profil:", error.message);
                }
            }
        },
    },
};
</script>

<template>
    <div class="container">
        <div class="row">
            <UserPanel></UserPanel>
            <div class="col-xl-6 offset-xl-3 col-md-8 offset-md-2 col-10 offset-1">
                <form @submit="updatePassword">
                    <div class="update-password">
                        <h3 class="my-5 fw-bold">Mettez à jour votre mot de passe</h3>
                        <div class="mb-4">
                            <label for="new-password">Nouveau mot de passe</label>
                            <input v-model="newPassword" type="password" id="new-password" class="ms-2" required />
                        </div>
                        <div class="mb-3">
                            <label for="confirm-new-password">Confirmer le nouveau mot de passe</label>
                            <input v-model="confirmNewPassword" type="password" id="confirm-new-password" class="ms-2"
                                required />
                        </div>
                    </div>
                    <div class="text-center">
                        <button type="submit" class="my-5 text-uppercase fw-bold">Mettre à jour</button>
                    </div>
                    <p v-if="error" class="error">{{ error }}</p>
                    <p v-if="successMessage" class="success">{{ successMessage }}</p>
                </form>
            </div>
        </div>
    </div>
</template>

<style scoped>
.update-password {
    font-family: 'Arapey', serif;
}

input {
    border: 0;
    border-bottom: 1px solid #000;
    font-size: 18px;
}

label {
    color: grey;
    font-size: 15px;
}

button {
    min-height: 50px;
    width: 200px;
    border: 2px solid rgb(227, 167, 14);
    background-color: white;
    font-size: 20px;
    color: black !important;
}

@media (min-width: 768px) { /* > col-md */
    button {
    width: 300px; 
  }
}

button:hover {
    background-color: rgb(227, 167, 14);
    color: white;
}
</style>
