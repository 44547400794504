<script>
import UserPanel from "../components/UserPanel.vue"

export default {
    components: {
        UserPanel,
    }
};
</script>

<template>
    <div class="container">
        <div class="row">
            <UserPanel></UserPanel>
            <div class="col-md-10 offset-md-1">
                <h3 class="mt-5 fw-bold text-uppercase">Ma Wishlist</h3>
                <p class="my-4 fs-6">Créez votre Sélection en ajoutant les articles que vous aimez. Vous pouvez
                    ajouter jusqu’à 50 articles, vérifier leur disponibilité et les ajouter à votre Panier à
                    tout moment.</p>
                <hr>
                <div class="wishlist_user d-flex justify-content-between align-items-center">
                    <p class="text-uppercase fw-bold fs-4">La sélection de (USER)</p>
                    <div class="share_wishlist">
                        <a class="d-flex align-items-center" href="">
                            <i class="fa-solid fa-share-nodes me-2"></i>
                            <p class="text-uppercase m-0 fs-5">Partager la wishlist</p>
                        </a>
                    </div>
                </div>
                <div class="my-5">
                    <p class="fs-6">Votre Wish List ne contient aucun article.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
h3,
p {
    font-family: 'Arapey', serif;
    color: black;
}

i {
    font-size: 20px;
    color: rgb(227, 167, 14);
}

.share_wishlist p:hover {
    text-decoration: underline;
}
</style>